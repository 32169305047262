<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import { tableData } from "./data-advancedtable";
import simplebar from "simplebar-vue";
import Multiselect from "vue-multiselect";
import {
  required
} from "vuelidate/lib/validators";
import {lookup} from '@/apis/common'
import {saveClient,queryClient,deleteClient,uniqueName} from '@/apis/client'
export default {
  page: {
    title: "客户管理",
    meta: [{ name: "description", content: appConfig.description }]
  },
  validations: {
    profileForm: {
        clientName: { 
            required,
            async isUnique (value) {
                if (value === '') return true
                let result = false
                await uniqueName({clientName:value,clientId:this.profileForm.clientId}).then((res)=>{
                    result = res.data;
                }).catch(()=>{
                    result = false
                })
                return result
            } 
        },
    },
  },
  components: { Layout,simplebar,Multiselect },
  data() {
    return {
      title: "客户管理",
      showType: "list",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      tableData: tableData,
      items:[],
      showForm:false,
      filter:{
        clientName:"",
        tags:""
      },
      lookupData:{
        clientSource:[],
        companyType:[],
        industry:[]
      },
      lookupDataSelected:{
        clientSource:[],
        companyType:[],
        industry:[]
      },
      submitted:false,
      profileForm:{
        clientId:"",
        clientNo:"",
        clientName:"",
        clientNameEn:"",
        address:"",
        addressEn:"",
        profile:"",
        tags:"",
        source:"",
        companyType:"",
        industry:"",
        contact:{
            contact:"",
            email:"",
            mobile:"",
            title:"",
            department:""
        }
      },
      profileFormInit:{
      },
      pageRequest:{
        pageNum:1,
        pageSize:10
      }
    };
  },
  computed: {
  },
  watch:{
    pageRequest:{
        pageNum: function () {
            this.loadData()
        },
    }
  },
  created(){
    this.profileFormInit = {...this.profileForm}
    this.init();
    this.loadData();
  },
  mounted() {
  },
  methods: {
    async init(){
      await lookup({"type":"COMPANY-TYPE,CLIENT-SOURCE,INDUSTRY"}).then((res)=>{
        this.lookupData.companyType = res.data["COMPANY-TYPE"];
        this.lookupData.clientSource = res.data["CLIENT-SOURCE"];
        this.lookupData.industry = res.data["INDUSTRY"];
      })
    },
    getParams(){
        return {...this.pageRequest,filter:this.filter}
    },
    loadData(){
        const params = this.getParams();
        queryClient(params).then((res)=>{
            this.tableData = res.data.map((obj) => {
                return {
                ...obj,
                tagList:obj.tags.split(",")}
        });
            this.totalRows = res.page.total;
        });
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    handleNewClient(){
        this.showForm = true;
    },
    handleSaveClient(){
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.profileForm.$invalid) {
            return;
        } else {
            const params ={...this.profileForm}
            saveClient(params).then(()=>{
                this.loadData();
                this.showForm = false;
                this.resetForm();
            });
        }
    },
    handleCancleClient(){
        this.showForm = false;
        this.resetForm();
    },
    handleDetail(client){
        this.$router.push({
          name: 'client-profile',
          params: {
            clientNo: client.clientNo
          }
        })
    },
    handleDelete(clientId){
        deleteClient([clientId])
    },
    resetForm(){
        this.submitted = false;
        this.profileForm = {...this.profileFormInit}
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row mt-4">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body" v-if="!showForm">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="mb-3">
                                <h5 class="card-title">客户列表<span
                                        class="text-muted fw-normal ms-2">{{totalRows}}</span></h5>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                                <div>
                                    <ul class="nav nav-pills">
                                        <li class="nav-item">
                                            <a class="nav-link" v-bind:class="showType=='list' ? 'active' : ''" data-bs-toggle="tooltip" title="List" @click="showType='list'"><i class="uil uil-list-ul"></i></a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" v-bind:class="showType=='grid' ? 'active' : ''" data-bs-toggle="tooltip" data-bs-placement="top" title="Grid"  @click="showType='grid'"><i class="uil uil-apps"></i></a>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <a href="#" class="btn btn-light" @click="handleNewClient"><i class="uil uil-plus me-1"></i> 新客户</a>
                                </div>
                            </div>
                        </div><!-- end col -->
                    </div><!-- end row -->
                    <div v-if="showType=='grid'">
                        <div class="row">
                            <div class="col-xl-4 col-sm-6" v-for="row in tableData" :key="row.clientId">
                                <div class="card">
                                    <div class="card-body p-4">
                                        <div class="d-flex align-items-start">
                                            <div class="flex-grow-1 overflow-hidden">
                                                <h5 class="font-size-15 mb-1 text-truncate"><a
                                                        href="pages-profile.html" class="text-dark">{{row.clientName}}</a></h5>
                                                    <span class="badge badge-soft-success me-1" v-for="tag in row.tagList" :key="row.clientId+tag">{{tag}}</span>
                                            </div>
                                        </div>
                                        <p class="mt-4 text-muted">{{row.address}}</p>
                                        <div class="pt-2">
                                            <button type="button" class="btn btn-primary btn-sm w-md text-truncate ms-2" @click="handleDetail(row)"><i class="bx bx-message-square-dots me-1 align-middle"></i> 档案</button>
                                        </div>
                                    </div>
                                    <!-- end card body -->
                                </div><!-- end card -->
                            </div><!-- end col -->
                        </div><!-- end row -->
                    </div>
                    <div v-if="showType=='list'">
                        <div class="table-responsive">
                          <simplebar class="h-100">
                            <table class="table align-middle table-nowrap table-check">
                                <thead>
                                    <tr>
                                        <th scope="col">客户名称</th>
                                        <th scope="col">客户英文名</th>
                                        <th scope="col">地址</th>
                                        <th scope="col">标签</th>
                                        <th style="width: 80px; min-width: 80px;">操作</th>
                                    </tr><!-- end tr -->
                                </thead><!-- end thead -->
                                <tbody>
                                    <tr v-for="row in tableData" :key="row.clientNo">
                                        <td> <a href="javascript:;" class="text-body fw-medium" @click="handleDetail(row)">{{row.clientName}}</a></td>
                                        <td>{{row.clientNameEn}}</td>
                                        <td>{{row.address}}</td>
                                        <td>
                                            <div class="d-flex gap-2">
                                                <a href="#" class="badge badge-soft-primary" v-for="tag in row.tagList" :key="row.clientId+tag">{{tag}}</a>
                                            </div>
                                        </td>
                                        <td>
                                            <b-dropdown variant="link" menu-class="dropdown-menu-end">
                                                <template v-slot:button-content>
                                                    <button class="btn btn-light btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i class="uil uil-ellipsis-h"></i>
                                                    </button>
                                                </template>
                                                <b-dropdown-item @click="handleDetail(item)" ><i class="mdi mdi-folder-outline me-1"></i>查看</b-dropdown-item>
                                                <b-dropdown-item @click="handleEdit(item)" ><i class="mdi mdi-folder-outline me-1"></i>修改</b-dropdown-item>
                                                <b-dropdown-item @click="handleDelete(item)"><i class="mdi mdi-file-outline me-1"></i>删除</b-dropdown-item>
                                            </b-dropdown>                                               
                                        </td>
                                    </tr><!-- end tr -->
                                </tbody><!-- end tbody -->
                            </table><!-- end table -->
                          </simplebar>
                        </div>
                     
                    </div>
                    <div class="row g-0 text-center text-sm-start" v-if="totalRows>0">
                        <div class="col-sm-6">
                            <div>
                                <p class="mb-sm-0">Showing {{(pageRequest.pageNum-1)*pageRequest.pageSize+1}} to {{pageRequest.pageNum*pageRequest.pageSize}} of {{totalRows}} entries</p>
                            </div>
                        </div>
                        <!-- end col -->
                        <div class="col-sm-6">
                            <ul class="pagination pagination-rounded justify-content-center justify-content-sm-end mb-sm-0">
                              <b-pagination v-model="pageRequest.pageNum" :total-rows="totalRows" :per-page="pageRequest.pageSize"></b-pagination>
                            </ul><!-- end ul -->
                        </div><!-- end col -->
                    </div><!-- end row -->                    
                </div><!-- end card body -->
                <div class="card-body" v-if="showForm">
                    <div class="card-body">
                        <h5 class="card-title mb-4">客户资料</h5>
                        <form class="needs-validation">
                            <div class="card border shadow-none mb-5">
                                <div class="card-header d-flex align-items-center">
                                    <div class="flex-shrink-0 me-3">
                                        <div class="avatar">
                                            <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                                                01
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1">
                                        <h5 class="card-title">基本信息</h5>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div>
                                        <div class="row">
                                            <div class="col-lg-6 mb-3">
                                                    <label class="form-label" for="gen-info-name-input">公司名称(当地语言)</label>
                                                    <input type="text" class="form-control" id="gen-info-name-input" placeholder="公司名称" v-model="profileForm.clientName">
                                                    <div
                                                        v-if="submitted && $v.profileForm.clientName.$error"
                                                        class="invalid-feedback"
                                                        >
                                                            <span v-if="!$v.profileForm.clientName.required">
                                                                公司名称不能为空.
                                                            </span>
                                                            <span v-if="!$v.profileForm.clientName.isexist">
                                                                公司名称已存在.
                                                            </span>
                                                    </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="mb-3">
                                                    <label class="form-label" for="gen-info-name-en-input">公司名称(英文)</label>
                                                    <input type="text" class="form-control" id="gen-info-name-en-input" placeholder="请输入英文公司名称" v-model="profileForm.clientNameEn">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6 mb-3">
                                                    <label class="form-label" for="gen-info-address-input">地址(当地语言)</label>
                                                    <input type="text" class="form-control" id="gen-info-address-input" placeholder="请输入地址" v-model="profileForm.address">
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="mb-3">
                                                    <label class="form-label" for="gen-info-address-en-input">地址(英文)</label>
                                                    <input type="text" class="form-control" id="gen-info-address-en-input" placeholder="请输入英文地址" v-model="profileForm.addressEn">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-12 mb-3">
                                                    <label class="form-label" for="gen-info-profile-input">客户简介</label>
                                                    <textarea class="form-control" id="gen-info-profile-input" v-model="profileForm.profile"></textarea>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6 mb-3">
                                                <label class="form-label" for="gen-info-industry-input">所属行业</label>
                                                <multiselect 
                                                id="gen-info-industry-input"
                                                v-model="lookupDataSelected.industry" 
                                                :options="lookupData.industry"
                                                label="label"
                                                track-by="value"
                                                ></multiselect>
                                            </div>
                                            <div class="col-lg-6 mb-3">
                                                <label class="form-label" for="gen-info-companyType-input">客户类型</label>
                                                <multiselect 
                                                id="gen-info-companyType-input"
                                                v-model="lookupDataSelected.companyType" 
                                                :options="lookupData.companyType"
                                                label="label"
                                                track-by="value"
                                                ></multiselect>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6 mb-3">
                                                <label class="form-label" for="gen-info-clientSource-input">客户来源</label>
                                                <multiselect 
                                                id="gen-info-clientSource-input"
                                                v-model="lookupDataSelected.clientSource" 
                                                :options="lookupData.clientSource"
                                                label="label"
                                                track-by="value"
                                                ></multiselect>
                                            </div>
                                            <div class="col-lg-6 mb-3">
                                                <label class="form-label" for="gen-info-tags-input">标签</label>
                                                <input type="text" class="form-control" id="gen-info-tags-input" placeholder="请输入标签逗号分割" v-model="profileForm.tags">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card border shadow-none mb-5">
                                <div class="card-header d-flex align-items-center">
                                    <div class="flex-shrink-0 me-3">
                                        <div class="avatar">
                                            <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                                                02
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1">
                                        <h5 class="card-title">联系人信息</h5>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div>
                                        <div class="row">
                                            <div class="col-lg-6 mb-3">
                                                    <label class="form-label" for="gen-info-contact-input">联系人</label>
                                                    <input type="text" class="form-control" id="gen-info-contact-input" placeholder="请输入联系人" v-model="profileForm.contact.contact">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6 mb-3">
                                                    <label class="form-label" for="gen-info-department-input">部门</label>
                                                    <input type="text" class="form-control" id="gen-info-department-input" placeholder="请输入部门" v-model="profileForm.contact.department">
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="mb-3">
                                                    <label class="form-label" for="gen-info-title-input">职位</label>
                                                    <input type="text" class="form-control" id="gen-info-title-input" placeholder="请输入职位" v-model="profileForm.contact.title">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6 mb-3">
                                                    <label class="form-label" for="gen-info-mobile-input">手机号码</label>
                                                    <input type="text" class="form-control" id="gen-info-mobile-input" placeholder="请输入联系人手机号码" v-model="profileForm.contact.mobile">
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="mb-3">
                                                    <label class="form-label" for="gen-info-email-input">邮箱地址</label>
                                                    <input type="text" class="form-control" id="gen-info-email-input" placeholder="请输入联系人邮箱地址" v-model="profileForm.contact.email">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="text-end">
                                <button type="button" class="btn btn-primary w-sm" @click="handleSaveClient">保存</button>
                                <button type="button" class="btn btn-success w-sm ms-1" @click="handleCancleClient">返回</button>
                            </div>
                        </form>
                        <!-- end form -->
                    </div>
                </div>
            </div><!-- end card -->
        </div><!-- end col -->
    </div><!-- end row -->
  </Layout>
</template>
<style lang="scss" scoped>
.border-light {
  border-color: #f8f7fc !important;
}
.bg-light {
    background-color: rgba(248, 247, 252,1)!important;
}
.search-box{
  .form-control{
      border-radius: 30px;
      padding-left: 40px;
  }
  .search-icon{
      font-size: 16px;    
      position: absolute;
      left: 13px;
      top: 2px;
      color: #959396;
      line-height: 38px;
  }
}
</style>