const tableData = [
  {
    clientId:'C00001',
    clientType:'Brand',
    name: '深圳绿朴可持续发展',
    nameEn: 'Shenzhen GreenPont',
    address: 'Room 3B-1,Torch Building, No.22, Yanshan Road, Shekou, Nanshan District, Shenzhen, China',
    tags: 'PUMA',
    createDate: '2018/12/04',
  },
  {
    clientId:'C00002',
    clientType:'Factory',
    name: '江苏东洲服装有限公司',
    nameEn: 'Jiangsu Dongzhou Garment CO.,LTD.',
    address: '中国江苏省宿迁市泗阳县经济开发区黄河南路88号',
    tags: 'PUMA',
    createDate: '2018/12/04',
  }
];

export { tableData };
